import IndexedDbHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';

import type * as FileStoreRecordModule from 'o365.pwa.declaration.shared.dexie.objectStores.FileStoreFile.d.ts';

export class FileStoreRecord implements FileStoreRecordModule.FileStoreRecord {
    static fileStoreRecordDexieSchema: string = "[primKey+appID], pdfRef, thumbnailRef, appID";

    public primKey: string;
    public mimeType: string;
    public data: ArrayBuffer;
    public filename: string;
    public filesize: number;
    public extension: string;
    public pdfRef: string | null;
    public thumbnailRef: string | null;
    public originalRef: string | null;
    public chunks: Array<FileStoreRecordModule.IChunks> | null;
    public appID: string;
    public CRC: number | null;

    public get dataAsBlob(): Blob {
        return new Blob([this.data], {
            type: this.mimeType
        });
    }

    constructor(options: FileStoreRecordModule.IFileStoreRecordOptions<ArrayBuffer>) {
        this.primKey = options.primKey;
        this.mimeType = options.mimeType;
        this.data = options.data;
        this.filename = options.filename;
        this.filesize = options.filesize;
        this.extension = options.extension;
        this.pdfRef = options.pdfRef ?? null;
        this.thumbnailRef = options.thumbnailRef ?? null;
        this.originalRef = options.originalRef ??  null;
        this.chunks = options.chunks ?? null;
        this.appID = options.appID;
        this.CRC = options.fileCrc;
    }

    static async fromBlob(options: FileStoreRecordModule.IFileStoreRecordOptions<Blob>) {
        const dataAsArrayBuffer = await options.data.arrayBuffer();

        const fileStoreRecordOptions = <FileStoreRecordModule.IFileStoreRecordOptions<ArrayBuffer>>{
            ...options,
            data: dataAsArrayBuffer
        };

        if (options.pdfRef) {
            fileStoreRecordOptions.pdfRef = options.pdfRef;
        }

        return new FileStoreRecord(fileStoreRecordOptions);
    }

    public async save(): Promise<void> {
        await IndexedDbHandler.updateFileStoreRecord(this);
    }

    public async delete(): Promise<void> {
        await IndexedDbHandler.deleteFileStoreRecord(this);
    }
}

export default FileStoreRecord;